/**
 * Copyright © 2024 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";
import * as moment from 'moment';
import {Uuid} from "../util/uuid";

type UserPath = {
  id: string;
  idOfSession: string;
  url: string;
  baseUrl: string;
  urlCode: string;
  startTime: Date;
  endTime: Date;
  dwell: number;
  data: Record<string, string>;
};

type SubPath = {
  id: string;
  idOfUserPath: string;
  subUrl: string;
  action: string;
  startTime: Date;
  endTime: Date;
  data: Record<string, string>;
  info: string;
};

type UserSession = {
  id: string;
  userId: string;
  masqueradeId: string;
  networkId: string;
  startTime: Date;
  endTime: Date;
  dwell: number;
  pathCount: number;
};

type HydratedUserSession = {
  userSession: UserSession;
  paths: Array<UserPath>;
  subPaths: Array<SubPath>;
};

export class LocalSessionPathsInfo {
  private static instance: LocalSessionPathsInfo;
  private static readonly SESSION_PATHS_INFO_KEY = 'sessionPathsInfo';

  info: HydratedUserSession;

  private constructor() {
  }

  public static obtain(): LocalSessionPathsInfo {
    if (!this.instance) {
      this.instance = new LocalSessionPathsInfo();
    }
    this.instance.refresh();
    return this.instance;
  }

  private save() {
    LocalStorage.obtain().setItem(LocalSessionPathsInfo.SESSION_PATHS_INFO_KEY, this.info);
    console.log(this.info, this.info.paths.length);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalSessionPathsInfo.SESSION_PATHS_INFO_KEY);
    this.info = _.isEmpty(localStorageInfo) ? _.isEmpty(this.info) ? {} : this.info : localStorageInfo;
  }

  newSession() {
    this.refresh();
    this.info = {
      userSession: {
        id: Uuid.generate(),
        userId: null,
        masqueradeId: null,
        networkId: null,
        startTime: new Date(),
        endTime: null,
        dwell: 0,
        pathCount: null
      },
      paths: [],
      subPaths: []
    };
    this.save();
  }

  closeOffSession() {
    this.refresh();
    this.updateLastPath();
    this.info.userSession.endTime = new Date();
    this.info.userSession.dwell = moment(this.info.userSession.endTime).diff(this.info.userSession.startTime, 'second');
    this.save();
    return this.info;
  }

  private updateLastPath() {
    if (this.info.paths.length > 0) {
      const currentPath = this.info.paths[this.info.paths.length - 1];
      currentPath.endTime = new Date();
      currentPath.dwell = moment(currentPath.endTime).diff(currentPath.startTime, 'second');
    }
  }

  newPath(path: UserPath) {
    if (!this.info.paths) {
      this.newSession();
    }
    this.updateLastPath();
    path.id = Uuid.generate();
    path.startTime = new Date();
    path.idOfSession = this.info.userSession.id;
    path.data = path.data || {};
    this.info.paths.push(path);
    this.save();
  }
}
